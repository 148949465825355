import React from "react";
import PlanGuard from "../components/PlanGuard";
import DashboardHeading from "./components/DashboardHeading";
import EmailedCreators from "../emailedcreators";

const MessagedCreator = () => {

  return (
    <PlanGuard>
      <div className="flex flex-col">
        <DashboardHeading />
        <EmailedCreators />
        {/* <ReusableTable
          cols={columns}
          rows={data}
          pageSize={10}
          TableTitleComponent={CustomTableTitle}
        /> */}
        
      </div>
    </PlanGuard>
  );
};

export default MessagedCreator;
