import { Modal, Switch } from "antd";

import {
  BarChartSquare02,
  ChevronDown,
  ChevronSelectorVertical,
  ChevronUp,
  Clock,
  HelpCircle,
  Lightning01,
  Logout01,
  Mail01,
  Settings02,
  ShoppingBag02,
  User03,
  Users03,
} from "untitledui-js-base";

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "../constants/path";
import { useAppDispatch, useAppSelector } from "../hooks";
import { clearAdminState } from "../store/admin";
import { resetAssistantTables } from "../store/assistant";
import { changeCustomerId, changeLoading, logout } from "../store/auth";
import { removeFilter } from "../store/creators";
import {
  adminChangedCustomer,
  clearState,
  setSelectedStoreId,
} from "../store/shops";
import { resetMessagedCreators } from "../store/statistics";
import { clearSubscriptions } from "../store/subscriptions";
import SidebarSelector from "./SidebarSelector";
import { selectIsDarkMode, setTheme, toggleTheme } from "../store/theme";
import { useSelector } from "react-redux";

const dashboardItems = [
  {
    id: "analytics",
    label: "Analytics",
    path: "/",
  },
  {
    id: "messagedcreators",
    label: "Messaged Creators",
    path: "/messagedcreators",
  },
  {
    id: "emailedcreators",
    label: "Emailed Creators",
    path: "/emailedcreators",
  },
];

const menuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <BarChartSquare02 size={"20"} />,
    subItems: dashboardItems,
    opened: true,
  },
  {
    id: "automations",
    label: "Automations",
    path: "/automations",
    icon: <Lightning01 size={"20"} />,
    opened: false,
  },
  {
    id: "emailautomations",
    label: "Email Automations",
    path: "/email-automations",
    icon: <Mail01 size={"20"} />,
    opened: false,
  },
];

const crmItems = [
  {
    id: "mycreators",
    label: "My Creators",
    path: "/my-creators",
    icon: <User03 size={"20"} />,
  },
  {
    id: "groups",
    label: "Groups",
    path: "/groups",
    icon: <Users03 size={"20"} />,
  },
];

const manageMenus = [
  {
    id: "shopmanagement",
    label: "Shops",
    path: "/shopmanagement",
    icon: <ShoppingBag02 size={"20"} />,
  },
  {
    id: "subscriptions",
    label: "Subscriptions",
    path: "/subscriptions",
    icon: <Clock size={"20"} />,
  },
];

const Sidebar: React.FC = () => {
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const pathName = useLocation().pathname;
  const [profileMenu, setProfileMenu] = useState<boolean>(false);
  const [menu, setMenu] = useState(menuItems);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDarkMode = useSelector(selectIsDarkMode);
  const auth = useAppSelector((state) => state.auth);
  const customer_id = auth?.meInfo?.customer_id;
  const shops = useAppSelector((state) => state.shops);
  const admin = useAppSelector((state) => state.admin);
  // console.log("auth12321", auth.meInfo.tier)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleProfileMenu = () => {
    setProfileMenu((prev) => !prev);
  };

  const handleLogout = async () => {
    navigate(PATH.auth.login, { replace: true });
    await dispatch(logout());
    dispatch(clearState());
    dispatch(clearAdminState());
    dispatch(clearSubscriptions());
    localStorage.clear();
    sessionStorage.clear();
    dispatch(changeLoading(false));
  };

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      dispatch(setTheme(savedTheme === "dark"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [isDarkMode]);

  const handleNavigate = (getMenuItem: any) => {
    if (getMenuItem.id === "dashboard") {
      setMenu((prev) =>
        prev.map((item) =>
          item.id === "dashboard" ? { ...item, opened: !item.opened } : item
        )
      );
    } else {
      const selectedShop = (shops.shops?.[customer_id] || []).find(
        (shop) => shop.shop_id === shops.selectedStoreId
      );
      const userInactive = ["inactive", "payment_pending"].includes(
        (selectedShop || {})?.status
      );
      if (
        userInactive &&
        ["/automations", "/email-automations"].includes(getMenuItem.path)
      ) {
        toast.error(
          "Please purchase and link a subscription with this shop to access this feature"
        );
      }
      navigate(getMenuItem.path);
    }
  };

  const handleStoreIdChange = (value: any) => {
    dispatch(setSelectedStoreId({ value, customer_id })); // To change the current shop id
    dispatch(resetAssistantTables()); // To signal that the store id changed for the assistant store
    dispatch(resetMessagedCreators()); // To signal that the store id changed for the statistics store
    dispatch(removeFilter());
  };

  const handleCustomerChange = (value: any) => {
    const customersList = admin.customersList;
    const customer = customersList.find(
      (customer) => customer.customer_id === value
    );
    let customer_id = value;
    let stripe_customer_id = null;
    if (!customer) {
      return;
    } else {
      stripe_customer_id = customer.stripe_customer_id;
    }
    const payload = {
      customer_id,
      stripe_customer_id,
    };
    console.log(
      "Changing selected customer. New customer id:",
      customer_id,
      "New stripe customer id:",
      stripe_customer_id
    );
    dispatch(changeCustomerId(payload)); // To change the current customer id
    dispatch(clearSubscriptions()); // To signal that the customer id changed for the assistant store
    dispatch(adminChangedCustomer()); // To set selected shopid to null and reset the shop list
    dispatch(removeFilter());
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target as Node)
      ) {
        setProfileMenu(false);
      }
    };

    if (profileMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup event listener on unmount
    };
  }, [profileMenu]);

  return (
    <div className="shrink-0 w-72 pt-3 bg-secondary-default flex flex-col border-r border-primary relative rounded-r-xl shadow-md space-between">
      {/* Company logo */}
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={closeModal}
        width={640}
        transitionName=""
        maskClosable={false}
      >
        <h1 className="text-2xl font-bold text-black mb-3">
          Welcome to Tutorials!
        </h1>
        <h3 className="text-lg font-semibold text-black mb-4">
          You can find our tutorial video below. If you have any questions,
          please contact us at{" "}
          <span className="text-blue-700">team@reacherapp.com</span> with your
          customer ID.
        </h3>
        <div className="flex justify-center mb-4">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VsPPXJKp-Vk?si=LpxM8VpW8Lo1waq8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full h-64 sm:h-80 md:h-96 lg:h-[450px]"
          ></iframe>
        </div>
        <div className={`flex justify-end mb-4 w-full mt-4`}>
          <button
            className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4  transition duration-300 ease-in-out"
            onClick={closeModal}
          >
            OK!
          </button>
        </div>
      </Modal>
      <div className="overflow-y-auto w-full">
        <div className="flex mb-6 mx-4">
          <img
            src="/reacher_logo.svg"
            alt="Reacher Logo"
            width="150"
            height="50"
            onClick={() => navigate(PATH.home)}
            style={{ cursor: "pointer" }} // Optional: To indicate it's clickable
          />
        </div>
        {/* Selectors */}
        {admin.isAdmin && (
          <div className="flex mb-2 mx-4">
            <SidebarSelector
              options={admin.customersList.map((customer) => ({
                value: customer.customer_id,
                label: customer.name,
              }))}
              value={parseInt(auth.meInfo.customer_id, 10)}
              onChange={(value) => handleCustomerChange(value)}
              placeHolder="Choose Customer"
              LeftIcon={<User03 size="20" className="text-quaternary-default" />}
            />
          </div>
        )}

        <div className="flex mb-2 mx-4">
          <SidebarSelector
            options={(shops.shops?.[customer_id] || []).map((shop) => ({
              value: shop.shop_id,
              label: shop.shop_name,
            }))}
            value={shops.selectedStoreId}
            onChange={(value) => handleStoreIdChange(value)}
            placeHolder="Choose Shop"
            LeftIcon={<ShoppingBag02 size="20" className="text-quaternary-default" />}
          />
        </div>

        {/* Navigation buttons */}
        <nav className="flex flex-col gap-1 items-center mt-5 mx-3">
          {menu
            .filter(
              (menuItem) =>
                !menuItem.tier ||
                (auth.meInfo?.tier && menuItem.tier === auth.meInfo.tier)
            )
            .map((menuItem, index) => (
              <div
                key={`menu-items-${index}`}
                id={`menu-items-${index}`}
                className={`w-full text-base text-tertiary-default group relative cursor-pointer flex flex-col duration-300 ease-in-out font-medium
            ${
              pathName !== "/" && pathName === menuItem.path
                ? "!text-primary-default"
                : ""
            }
            `}
              >
                <div
                  className="flex w-full items-center justify-between gap-6 py-2 rounded-md hover:!bg-secondary-hover hover:!text-primary-default px-4"
                  onClick={() => handleNavigate(menuItem)}
                >
                  <div className="flex items-center w-full gap-3">
                    {menuItem.icon}
                    {menuItem.label}
                  </div>

                  {menuItem.subItems && menuItem.subItems.length > 0 ? (
                    menuItem.opened ? (
                      <ChevronUp
                        size="22"
                        className="justify-self-end text-quaternary-default"
                      />
                    ) : (
                      <ChevronDown
                        size="22"
                        className="justify-self-end text-quaternary-default"
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>

                {menuItem.subItems && menuItem.opened && (
                  <div className="flex flex-col gap-1 ml-6 mb-1 pl-3 border-l">
                    {menuItem.subItems.map((subItem) => (
                      <Link
                        key={subItem.id}
                        to={subItem.path}
                        className={`text-base group text-tertiary-default relative cursor-pointer rounded-md flex items-center gap-3 py-2 duration-300 ease-in-out whitespace-nowrap px-4 ${
                          pathName === subItem.path
                            ? "bg-primary-default !text-primary-default shadow-sm"
                            : "hover:!bg-secondary-hover hover:!text-primary-default"
                        }`}
                      >
                        {pathName === subItem.path && (
                          <div className="w-2 h-2 rounded-full bg-fg-brand-primary-default"></div>
                        )}
                        {subItem.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </nav>
        <div className="ml-2 mt-6">
          <div className="mb-1 text-quaternary-default font-semibold text-xs mx-4">
            CRM
          </div>
          {crmItems.map((value) => (
            <Link
              id={`crm-${value.path}`}
              to={value.path}
              className={`text-base text-tertiary-default font-medium group relative cursor-pointer rounded-md mr-3 flex items-center ml-1 gap-3 py-2 duration-300 ease-in-out px-4 ${
                pathName === value.path
                  ? "!text-primary-default"
                  : "hover:!bg-secondary-hover hover:!text-primary-default"
              }`}
            >
              {value.icon}
              {value.label}
            </Link>
          ))}
        </div>
        <div className="mt-6 ml-2">
          <div className=" text-quaternary-default font-semibold mb-1 text-xs mx-4">
            <h1>MANAGE</h1>
          </div>
          {manageMenus.map((menuItem, index) => (
            <div
              onClick={() => handleNavigate(menuItem)}
              key={`menu-items-${index}`}
              id={`menu-items-${index}`}
              className={`text-base text-tertiary-default font-medium group relative cursor-pointer rounded-md mr-3 flex items-center ml-1 gap-3 py-2 duration-300 ease-in-out px-4
            ${
              pathName === menuItem.path
                ? "!text-primary-default"
                : "hover:!bg-secondary-hover hover:!text-primary-default"
            }
            `}
            >
              {menuItem.icon}
              {menuItem.label}
            </div>
          ))}
        </div>
      </div>

      <div className="mt-auto relative " ref={profileMenuRef}>
        <div
          onClick={toggleProfileMenu}
          className="cursor-pointer flex items-center gap-4 border-t border-secondary p-5 px-4"
        >
          <div className="flex items-center gap-3 overflow-x-hidden">
            <div className="w-10 h-10 rounded-full border border-secondary shrink-0">
            <img
              src="/logo192.png"
              alt="Reacher Logo"
              width="150"
              height="50"
              style={{ cursor: "pointer" }} // Optional: To indicate it's clickable
            />
            </div>
            <div className="flex flex-col text-sm">
              <p className="font-semibold text-secondary-default">
                {auth.meInfo?.name}
              </p>
              <p className="text-tertiary-default line-clamp-1">
                {auth.meInfo?.email}
              </p>
            </div>
          </div>
          <ChevronSelectorVertical
            size={"20"}
            className="shrink-0 text-tertiary-default"
          />
        </div>
        {profileMenu && (
          <div className="w-60 p-1 bg-primary-default border border-secondary shadow-lg absolute left-6 bottom-3/4 rounded-lg ">
            <Link
              to="/myaccount"
              className={`w-full text-sm rounded-sm text-secondary-default  group relative cursor-pointer flex items-center gap-2 py-3 duration-300 ease-in-out font-medium hover:bg-primary-hover px-4`}
            >
              <Settings02 size="16" className="text-quaternary-default" />
              Account Settings
            </Link>

            <Link
              to="https://reacherapp.com/contact"
              target="_blank"
              className={`w-full text-sm rounded-sm text-secondary-default  group relative cursor-pointer flex items-center gap-2 py-2 duration-300 ease-in-out font-medium hover:bg-primary-hover px-4`}
            >
              <HelpCircle size="16" className="text-quaternary-default" />
              Contact Reacher
            </Link>

            <button
              className="w-full text-sm rounded-sm text-secondary-default  group relative cursor-pointer flex items-center gap-2 py-2 duration-300 ease-in-out font-medium hover:bg-primary-hover px-4"
              onClick={openModal}
            >
              <Lightning01 size={"16"} className="text-quaternary-default" />
              View Tutorial
            </button>

            {/* <div className="px-4 w-full py-2 text-sm text-secondary-default font-medium flex gap-2">
              <span>Theme</span>
              <Switch
                defaultChecked={isDarkMode}
                checkedChildren={"Dark"}
                unCheckedChildren={"Light"}
                onChange={handleToggleTheme}
              />
            </div> */}

            <div
              className={`w-full border-t text-sm text-fg-error-primary group relative cursor-pointer flex items-center font-semibold gap-2 py-3 duration-300 ease-in-out hover:menuItem-active px-4`}
              onClick={handleLogout}
            >
              <Logout01 size={"20"} />
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
