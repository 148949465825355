import React, { useEffect, useRef } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip, Input } from "antd";
import FormikTextArea from "../../../pages/components/formikForms/FormikTextField";

const MessageEntryTextField: React.FC = ({}) => {
  const textAreaRef = useRef<any>(null);
  useEffect(() => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;
      setTimeout(() => {
        textAreaElement.focus();
      }, 0);
    }
  }, []);
  const addCreatorName = (setFieldValue: any, name: string) => {
    if (textAreaRef.current?.resizableTextArea?.textArea) {
      const textAreaElement = textAreaRef.current.resizableTextArea.textArea;

      // Get the cursor and selection details
      const start = textAreaElement.selectionStart;
      const end = textAreaElement.selectionEnd;
      const value = textAreaElement.value;

      // Replace selected text or insert at cursor position
      const newValue =
        value.slice(0, start) + "{creator_name}" + value.slice(end);

      // Update the Formik field value
      setFieldValue(name, newValue);

      // Calculate the new cursor position after insertion/replacement
      const newCursorPosition = start + "{creator_name}".length;

      // Set focus and adjust the cursor position after the update
      setTimeout(() => {
        textAreaElement.focus();
        textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };

  const messageEntryLabel = (setFieldValue, name) => (
    <div className="flex items-center space-x-2 text-gray-700 font-medium">
      <Tooltip title="Please avoid using the word 'amazon' in the message entry.">
        <span className="flex items-center">
          Message Entry <InfoCircleOutlined style={{ marginLeft: 4, fontSize: '16px', color: '#888' }} />
        </span>
      </Tooltip>
      <Button
        type="dashed"
        onClick={() => addCreatorName(setFieldValue, name)}
        style={{
          padding: '2px 8px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          color: '#1890ff',
          borderColor: '#1890ff',
        }}
      >
        Add {`{creator_name}`}
      </Button>
    </div>
  );
  
  return (
    <FormikTextArea
      name="message_entry"
      newLabel={messageEntryLabel}
      maxLength={2000}
      helperText='Make sure to include "{creator_name}" to personalize the message.'
      restProps={{ ref: textAreaRef }}
    />
  );
};

export default MessageEntryTextField;
