// AutomationForm.tsx
import React, { useEffect } from "react";
import { Form, ErrorMessage, FormikValues } from "formik";
import InputField from "../../components/forms/InputField";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";
import FormikAddToList from "../../components/formikForms/FormikAddToList";
import WeeklyAutomationSchedule from "./WeeklyAutomationSchedule";
import TextError from "../../components/forms/TextError";
import {
  AUTOMATION_TYPE_OPTIONS,  
} from "./defaultFormData";
import { useAppDispatch, useAppSelector } from "../../../pages/hooks";
import { getRegionBasedFilters } from "../../../pages/store/automation";
import { Spin } from "antd";
import CustomFilters from "./CustomFilters";

interface AutomationFormProps {
  values: FormikValues;
  setFieldValue: any;
  validateField: any;
  shops: any;
  group: any;
  isSubmitting: boolean;
  onReturn: () => void;
}

const AutomationForm: React.FC<AutomationFormProps> = ({
  values,
  setFieldValue,
  validateField,
  shops,
  group,
  isSubmitting,
  onReturn,
}) => {
  const dispatch = useAppDispatch();
  const automation = useAppSelector((state) => state.automation);

  const filters = automation.filters?.[shops.selectedStoreRegion] || [];
  const loading = automation.loading;
  
  const format = "HH:mm";
  const timePickerStyles = {
    outline: "none", // Remove default outline
    boxShadow: "none", // Remove default shadow
    border: "1px solid #e5e7eb", // Border to match react-select
    padding: "8px", // Add some padding
    width: "86%", // Set width to 86%
    borderRadius: "4px", // Match rounded corners
    color: "gray", // Text color
    fontSize: "1rem", // Font size
    lineHeight: "1.5", // Line height
  };
  const inputStyles = {
    padding: "10px", // Add padding
    width: "86%", // Ensure it takes full width
    height: "40px", // Set a fixed height to match TimePicker
    color: "gray", // Text color
    fontSize: "1rem", // Font size to match TimePicker
    lineHeight: "1.5", // Line height to match TimePicker
  };

  useEffect(() => {
    if (
      shops.selectedStoreRegion &&
      (automation.filters?.[shops.selectedStoreRegion] || []).length === 0 && 
      !group?.group
    ) {
      dispatch(
        getRegionBasedFilters({ shopRegion: shops.selectedStoreRegion })
      );
    }
  }, [shops.selectedStoreRegion]);

  const creatorsFilters = filters.filter((filter: any) =>
    ["category", "followers", "products"].includes(filter.name)
  );
  const followersFilters = filters.filter((filter: any) =>
    ["age", "gender"].includes(filter.name)
  );
  const performanceFilters = filters.filter((filter: any) =>
    [
      "gmv",
      "engagement",
      "units",
      "views",
      "fulfillment_rate",
      "gpm",
      "video_views",
      "quickly_response",
      "videos",
      "video_gpm",
      "live_gpm",
      "high_sample_dispatch_rate"
    ].includes(filter.name)
  );

  console.log('performanceFilters21312312', performanceFilters)

  return (
    <Form className="mt-6 mb-2 w-full">
      <div className={group?.group ? group?.className : ""}>
        <InputField
          type="text"
          placeholder="i.e. Mystery Box New Year Sale"
          name="automation_name"
          label="Automation Name"
          size="md"
        />

        <div className="mb-6">
          <FormikMultipleSelectField
            isMultiple={false}
            name="automation_type"
            placeHolder="Select Automation Type"
            consistentLabel="Automation Type"
            options={AUTOMATION_TYPE_OPTIONS}
          />
          <ErrorMessage name={"automation_type"} component={TextError} />
        </div>

        {(values.automation_type === "Message + Product Card" ||
          values.automation_type === "Message + Target Collab" ||
          values.automation_type ===
            "Message + Target Collab + Target Collab Card") && (
          <FormikAddToList
            name="product_id"
            label="Product ID"
            placeHolder="i.e. 142735249054578"
            listItems={values.product_id}
            maxListLenght={20}
            disabled={
              values.automation_type === "Message" ||
              values.automation_type === "Message + Image"
            }
          />
        )}

        {!group?.group && (
          <WeeklyAutomationSchedule
            values={values}
            setFieldValue={setFieldValue}
            format={format}
            timePickerStyles={timePickerStyles}
            inputStyles={inputStyles}
            validateField={validateField}
          />
        )}

      {loading ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : (
        <>
            <div>
              <p className="block text-sm font-bold text-black mb-2">
                Automation Filters
              </p>

              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-10">
                <CustomFilters title="Creators" filters={creatorsFilters} />
                <CustomFilters title="Followers" filters={followersFilters} />
                <CustomFilters
                  title="Performance"
                  filters={performanceFilters}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex justify-between mb-4 w-full mt-2">
          {!group?.group && (
            <>
              <button
                className="bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
                disabled={isSubmitting}
                onClick={onReturn}
              >
                Return to Automations
              </button>
              <button
                className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out"
                type="submit"
              >
                Next
              </button>
            </>
          )}
        </div>
      </div>

      {group?.content || <div />}
    </Form>
  );
};

export default AutomationForm;
