import React, { useRef } from "react";
import { Form } from "formik";
import FormikTextArea from "../../components/formikForms/FormikTextField";
import { InfoCircleOutlined } from "@ant-design/icons";
import { MdTipsAndUpdates } from "react-icons/md";
import { Button, Tooltip } from "antd";
import MessageEntryTextField from "./MessageEntryTextField";

interface StepOneTwoFormProps {
  onPrev: () => void;
  group: any;
}

const StepOneTwoForm: React.FC<StepOneTwoFormProps> = ({ onPrev, group }) => {
  return (
    <Form className="mt-6 mb-2 w-full">
      <div className={group?.group ? group?.className : ""}>
        <label className="block text-sm font-medium text-black mb-2 flex items-center">
          <MdTipsAndUpdates className="mr-2" /> Tips and Tricks
        </label>
        <div className="bg-white border border-ant-input-border rounded-lg col-span-1 mb-5 inline-block">
          <span className="flex text-md text-black italic before:content-['●'] before:mr-2 p-2 mr-2">
            You can use {"{creator_name}"} to send a message with the creator's
            name!
          </span>
        </div>
        <MessageEntryTextField />
        {!group?.group && (
          <div className="flex justify-between mb-4 w-full mt-10">
            <button
              className="bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
              onClick={onPrev}
              type="button"
            >
              Back
            </button>

            <button
              className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out"
              type="submit"
            >
              Next
            </button>
          </div>
        )}
      </div>
      {group?.content || <div />}
    </Form>
  );
};

export default StepOneTwoForm;
