import React, { useState } from "react";
// import { RiDeleteBin2Line } from 'react-icons/ri';
import { Popover as AntdPopover } from "antd";
import { RiDeleteBin2Line } from "react-icons/ri";

const AutomationListActions = ({
  record,
  isRunning,
  isStopping,
  isDeleting,
  handleStart,
  handleStop,
  handleDelete,
  onRowClick,
  shops,
}) => {
  const [DeleteOpen, setDeleteOpen] = useState(false);
  const [StartOpen, setStartOpen] = useState(false);
  const [StopOpen, setStopOpen] = useState(false);

  const handleStopOpenChange = (newOpen: boolean) => {
    setStopOpen(newOpen);
  };

  const handleStartOpenChange = (newOpen: boolean) => {
    setStartOpen(newOpen);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setDeleteOpen(newOpen);
  };
  return (
    <div className="flex items-center justify-center space-x-3">
      {record.status === "stopped" ||
      record.status === "paused_by_limit" ||
      record.status === "failed" ? (
        <AntdPopover
          content={
            <div className="w-46">
              <h6 className="mb-2 font-semibold text-md text-green-400">
                Confirm Start
              </h6>
              <p style={{ color: "red" }}>
                Stop all other automations of the same type for this shop.
              </p>
              <div className="flex justify-end items-center">
                <button
                  className={`w-full mt-4 ${isRunning ? "bg-gray-400" : "bg-green-400 hover:bg-green-600"} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out`}
                  onClick={() => {
                    handleStart(record.automation_id, shops.selectedStoreId);
                  }}
                  disabled={isRunning}
                >
                  Start
                </button>
              </div>
            </div>
          }
          trigger="click"
          open={StartOpen}
          onOpenChange={handleStartOpenChange}
        >
          <button className="automation-start-button-bg hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">
            Start
          </button>
        </AntdPopover>
      ) : (
        <AntdPopover
          content={
            <div className="w-46">
              <h6 className="mb-2 font-semibold text-md text-red-400">
                Confirm Stop
              </h6>
              This will stop the automation immediately.
              <div className="flex justify-end items-center">
                <button
                  className={`w-full mt-4 ${isStopping ? "bg-gray-400" : "bg-red-400 hover:bg-red-600"} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out`}
                  onClick={() => {
                    handleStop(record.automation_id, shops.selectedStoreId);
                  }}
                  disabled={isStopping}
                >
                  Stop
                </button>
              </div>
            </div>
          }
          trigger="click"
          open={StopOpen}
          onOpenChange={handleStopOpenChange}
        >
          <button disabled={record.status === "completed"} className={`py-2 px-4 rounded-md transition duration-300 ease-in-out ${record.status === "completed" ? "bg-gray-300 text-white" :"automation-finish-and-save-bg hover:bg-red-600 text-white font-bold "}`}>
            Stop
          </button>
        </AntdPopover>
      )}
      <button
        className={`w-full bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out`}
        onClick={() => onRowClick && onRowClick(record)}
      >
        View/Edit
      </button>
      <AntdPopover
        content={
            <div className="w-46">
                <h6 className="mb-2 font-semibold text-md text-red-400 font-bold">
                    Confirm Deletion
                </h6>
                This will permanently delete the automation.
                <div className="flex justify-end items-center">
                    <button
                    className={`w-full mt-4 ${isDeleting ? 'bg-gray-400' : 'bg-red-400 hover:bg-red-600'} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out`}
                    onClick={() => {
                        handleDelete(record.automation_id, shops.selectedStoreId);
                    }}
                    disabled={isDeleting}
                    >
                    Delete
                    </button>
                </div>
            </div>
        }
        trigger="click"
        open={DeleteOpen}
        onOpenChange={handleOpenChange}
        >
          <button className="bg-red-400 hover:bg-red-600 text-white font-bold p-2 rounded-md transition duration-300 ease-in-out">
            <RiDeleteBin2Line className="text-white" size={24} />
          </button>
        </AntdPopover>
    </div>
  );
};

export default AutomationListActions;
