import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./layout";
import AddNewAutomationForm from "./components/AddNewAutomationForm";

const Automations = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  return (
    <Layout>
      <div>
        <div className="flex items-center justify-between pb-6 mb-6 border-b border-stroke border-gray-300">
          <h1 className="text-2xl text-blue-500 font-bold">Automations</h1>
        </div>
        <AddNewAutomationForm
          onReturn={() => navigate(`/automations`)}
          step={step}
          setStep={setStep}
        />
      </div>
    </Layout>
  );
};

export default Automations;
