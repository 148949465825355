import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { thunkAction } from "../helpers";
import clientService from "../helpers/client";
import { Api } from "../constants/api";

const initialState = {
  loading: false,
  creators: {},
  filters: {},
  groupfilters: {},
  groups: {},
  creatorLoading: true,
  filtersLoading: false,
  groupsLoading: true,
  singleGroup: {},
  singleGroupCreators: {},
  savedTags: {},
};

const CreatorSlice = createSlice({
  name: "creator",
  initialState,
  reducers: {
    setCreatorLoading(state, action) {
      state.creatorLoading = action.payload;
      return state;
    },
    setGroupLoading(state, action) {
      state.groupsLoading = action.payload;
      return state;
    },
    setFilterLoading(state, action) {
      state.filtersLoading = action.payload;
      return state;
    },
    removeFilter(state) {
      state.filters = {};
      return state;
    },
    setSavedTags(state, action) {
      state.savedTags = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreators.fulfilled, (state, action) => {
      state.loading = false;
      let key: string = `${action.meta.arg.shop_id}-${action.meta.arg.shop_region}`;
      if (action.meta.arg.filtersKey) {
        key = `${action.meta.arg.shop_id}-${action.meta.arg.shop_region}-${action.meta.arg.filtersKey}`;
      }
      state.creators = {
        ...state.creators,
        [key]: action.payload,
      };
      state.creatorLoading = false;
      return state;
    });
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.filters = action.payload;
      state.filtersLoading = false;

      return state;
    });
    builder.addCase(getGroupFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.groupfilters = action.payload;
      state.filtersLoading = false;

      return state;
    });
    builder.addCase(getSingleGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.singleGroup = {
        ...state.singleGroup,
        [action.meta.arg.id]: action.payload,
      };
      state.groupsLoading = false;

      return state;
    });
    builder.addCase(addTagsToCreators.fulfilled, (state) => {
      state.loading = false;
      state.filters = {};
      state.filtersLoading = true;

      return state;
    });
    builder.addCase(removeTagsToCreators.fulfilled, (state) => {
      state.loading = false;
      state.filters = {};
      state.filtersLoading = true;

      return state;
    });
    builder.addCase(getMyCreatorsByGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.singleGroupCreators = {
        ...state.singleGroupCreators,
        [action.meta.arg.group_id]: action.payload,
      };
      state.groupsLoading = false;
      return state;
    });

    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.loading = false;
      let key: string = `${action.meta.arg.shop_id}`;
      if (action.meta.arg.filtersKey) {
        key = `${action.meta.arg.shop_id}-${action.meta.arg.filtersKey}`;
      }
      state.groups = {
        ...state.groups,
        [key]: action.payload,
      };
      state.groupsLoading = false;

      return state;
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.loading = false;
      let key: string = `${action.meta.arg.shop_id}`;
      state.groups = {
        ...state.groups,
        [key]: [],
      };
      state.groupsLoading = true;
      return state;
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.loading = false;
      let key: string = `${action.meta.arg.shop_id}`;
      state.groups = {
        ...state.groups,
        [key]: [],
      };
      state.groupsLoading = true;
      return state;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.loading = false;
      let key: string = `${action.meta.arg.shop_id}`;
      state.groups = {
        ...state.groups,
        [key]: [],
      };
      state.singleGroup = {
        ...state.singleGroup,
        [action.meta.arg.id]: null,
      };
      state.groupsLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getCreators.pending), (state) => {
      state.loading = true;
      state.creatorLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getGroupFilters.pending), (state) => {
      state.loading = true;
      state.filtersLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getSingleGroup.pending), (state) => {
      state.loading = true;
      state.groupsLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(addTagsToCreators.pending), (state) => {
      state.loading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(removeTagsToCreators.pending), (state) => {
      state.loading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getMyCreatorsByGroup.pending), (state) => {
      state.loading = true;
      state.groupsLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getFilters.pending), (state) => {
      state.loading = true;
      state.filtersLoading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getGroups.pending), (state) => {
      state.loading = true;
      state.groupsLoading = true;
      return state;
    });
    builder.addMatcher(
      isAnyOf(deleteGroup.pending, updateGroup.pending),
      (state) => {
        state.loading = true;
        state.groupsLoading = true;
        return state;
      }
    );
    builder.addMatcher(isAnyOf(createGroup.pending), (state) => {
      state.loading = true;
      return state;
    });
    builder.addMatcher(isAnyOf(getCreators.rejected), (state) => {
      state.loading = false;
      state.creatorLoading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(getFilters.rejected), (state) => {
      state.loading = false;
      state.filtersLoading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(getGroupFilters.rejected), (state) => {
      state.loading = false;
      state.filtersLoading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(getSingleGroup.rejected), (state) => {
      state.loading = false;
      state.groupsLoading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(getGroups.rejected), (state) => {
      state.loading = false;
      state.groupsLoading = false;
      return state;
    });
    builder.addMatcher(
      isAnyOf(deleteGroup.rejected, updateGroup.rejected),
      (state) => {
        state.loading = false;
        state.groupsLoading = false;
        return state;
      }
    );
    builder.addMatcher(isAnyOf(getMyCreatorsByGroup.rejected), (state) => {
      state.loading = false;
      state.groupsLoading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(addTagsToCreators.rejected), (state) => {
      state.loading = false;
      return state;
    });
    builder.addMatcher(isAnyOf(removeTagsToCreators.rejected), (state) => {
      state.loading = false;
      return state;
    });
  },
});

export const getCreators = createAsyncThunk(
  "creator/getCreators",
  thunkAction((params: any) => {
    return clientService.post(Api.creator.getList, params);
  })
);

export const getGroups = createAsyncThunk(
  "creator/getGroups",
  thunkAction((params: any) => {
    return clientService.post(Api.groups.getList, params);
  })
);

export const getFilters = createAsyncThunk(
  "creator/getFilters",
  thunkAction((params: any) => {
    return clientService.get(Api.creator.filters(params["shop_id"]));
  })
);

export const getGroupFilters = createAsyncThunk(
  "creator/getGroupFilters",
  thunkAction(() => {
    return clientService.get(Api.groups.getFilter);
  })
);

export const createGroup = createAsyncThunk(
  "creator/createGroup",
  thunkAction((params: any) => {
    return clientService.post(Api.groups.createGroup, params);
  })
);

export const getSingleGroup = createAsyncThunk(
  "creator/getSingleGroup",
  thunkAction((params: any) => {
    return clientService.get(Api.groups.getSingleGroupById(params["id"]));
  })
);

export const getMyCreatorsByGroup = createAsyncThunk(
  "creator/getMyCreatorsByGroup",
  thunkAction((params: any) => {
    return clientService.post(Api.groups.getMyCreatorsByGroup, params);
  })
);

export const updateGroup = createAsyncThunk(
  "creator/updateGroup",
  thunkAction((params: any) => {
    return clientService.patch(
      Api.groups.getSingleGroupById(params["id"]),
      params["data"]
    );
  })
);

export const deleteGroup = createAsyncThunk(
  "creator/deleteGroup",
  thunkAction((params: any) => {
    return clientService.delete(Api.groups.getSingleGroupById(params["id"]), {
      data: params["data"],
    });
  })
);

export const addTagsToCreators = createAsyncThunk(
  "creator/addTagsToCreators",
  thunkAction((params: any) => {
    return clientService.post(Api.creator.addTag, params);
  })
);

export const removeTagsToCreators = createAsyncThunk(
  "creator/removeTagsToCreators",
  thunkAction((params: any) => {
    return clientService.post(Api.creator.removeTag, params);
  })
);

export const {
  setCreatorLoading,
  setFilterLoading,
  setGroupLoading,
  removeFilter,
  setSavedTags,
} = CreatorSlice.actions;
export default CreatorSlice.reducer;
