// CustomFilters.tsx
import React from "react";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";

interface Filter {
  name: string;
  label: string;
  options: { value: string; label: string }[];
}

interface CustomFiltersProps {
  filters: Filter[];
  title: string;
}

const CustomFilters: React.FC<CustomFiltersProps> = ({ filters, title }) => {
  return (
    <div>
      <p className={`block text-sm font-medium text-black mb-4`}>{title}</p>
      <div className="grid grid-cols-1 gap-4">
        {filters.map((filter) => (
          <div key={filter.name}>
            <FormikMultipleSelectField
              name={filter.name}
              placeHolder={filter.label}
              options={filter.options}
              label={filter.label}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomFilters;
