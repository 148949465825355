import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AutomationList from "./components/AutomationList";
import { getAutomations } from "../store/automation";
import { useAppDispatch, useAppSelector } from "../hooks";

import Layout from "./layout";

const Automations = (props: any) => {
  const navigate = useNavigate();
  const automation = useAppSelector((state) => state.automation);
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const singleCustomerAutomations =
    automation.data?.[shops.selectedStoreId] || [];

  useEffect(() => {
    if (auth?.meInfo?.customer_id && shops.selectedStoreId) {
      dispatch(
        getAutomations({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  }, [auth, shops.selectedStoreId]);

  const handleRowClick = (automation) => {
    navigate(`/automations/${automation.automation_id}`);
  };

  return (
    <Layout>
      <div>
        <div className="flex items-center justify-between pb-6 mb-6 border-b border-stroke border-gray-300">
          <h1 className="text-2xl text-blue-500 font-bold">Active Automations</h1>
          <button
            className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out mr-4"
            onClick={() => {
              navigate("/automations/create");
            }}
          >
            Create New Automation
          </button>
        </div>

        <AutomationList
          automations={singleCustomerAutomations}
          isAutomationsLoading={automation.loading}
          onRowClick={handleRowClick}
        />
      </div>
    </Layout>
  );
};

export default Automations;
