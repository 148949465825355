import React, { useState, useRef, useEffect, ReactElement } from "react";
import { Check, ChevronDown, ChevronUp, Inbox01 } from "untitledui-js-base";

interface SidebarSelectorProps {
  placeHolder?: string;
  value: number;
  options: { value: number; label: string }[];
  onChange: (value?: number) => void;
  LeftIcon?: ReactElement; // Add LeftIcon prop
  containerstyle?: object; // Add LeftIcon prop
  inputClassName?: string; // Add LeftIcon prop
  preDefinedValue?: boolean; // Add LeftIcon prop
}

const SidebarSelector: React.FC<SidebarSelectorProps> = ({
  placeHolder,
  value,
  options,
  onChange,
  LeftIcon,
  containerstyle,
  inputClassName,
  preDefinedValue = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [previousSelectedInput, setPreviousSelectedInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Initialize input value based on the value prop or the first option
  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setInputValue(selectedOption.label);
      setPreviousSelectedInput(selectedOption.label);
    } else if (options.length > 0 && preDefinedValue) {
      // If the value is not in the options, select the first option
      const firstOption = options[0];
      setInputValue(firstOption.label);
      setPreviousSelectedInput(firstOption.label);
      onChange(firstOption.value); // Trigger the change with the first option's value
    } else if(value === null && !preDefinedValue) {
      setInputValue("");
      setPreviousSelectedInput("");
    }
  }, [value, options, onChange]);

  // Filter options based on input value
  useEffect(() => {
    setFilteredOptions(
      inputValue
        ? options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        : options
    );
  }, [inputValue, options]);

  // Close dropdown when clicking outside and revert to previous selection
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setInputValue(previousSelectedInput);
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [previousSelectedInput]);

  const handleSelectOption = (optionValue: number) => {
    const selectedOption = options.find(
      (option) => option.value === optionValue
    );
    setIsOpen(false);
    if (selectedOption) {
      setInputValue(selectedOption.label);
      setPreviousSelectedInput(selectedOption.label);
      onChange(optionValue);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsOpen(true); // Open dropdown on typing
  };

  return (
    <div className={`relative w-full`} style={containerstyle || {}} ref={dropdownRef}>
      {/* Select input with search */}
      <input
        ref={inputRef}
        type="text"
        className={`bg-primary-default text-primary-default border border-primary outline-none focus:border-brand-default font-medium flex justify-between items-center px-4 pl-12 py-2 rounded-lg w-full ${inputClassName}`}
        value={inputValue}
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            setInputValue(previousSelectedInput);
            inputRef.current?.blur();
          } else {
            setInputValue("");
            inputRef.current?.focus();
          }
        }}
        onChange={handleInputChange}
        placeholder={placeHolder || "Select..."}
      />

      {/* Render the custom LeftIcon if provided */}
      {LeftIcon && (
        <span className="absolute left-4 top-2.5 cursor-pointer">
          {LeftIcon}
        </span>
      )}

      <span
        className="absolute right-4 top-3 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <ChevronUp size="18" className="text-quaternary-default" />
        ) : (
          <ChevronDown size="18" className="text-quaternary-default" />
        )}
      </span>

      {/* Dropdown options */}
      {isOpen && (
        <div className="absolute z-50 mt-1 py-1 flex flex-col gap-1 w-full bg-primary-default border border-primary rounded-lg shadow-lg max-h-60 overflow-y-auto custom-scrollbar">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`flex justify-between rounded-sm mx-1 items-center px-3 py-2 cursor-pointer text-secondary-default hover:bg-secondary-default ${
                  option.value === value ? "!bg-secondary-default" : ""
                }`}
                onClick={() => handleSelectOption(option.value)}
              >
                <span className="flex items-center gap-2">
                  {LeftIcon}
                  {option.label}
                </span>
                {option.value === value && (
                  <Check size="20" className="text-fg-brand-primary-default" />
                )}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 flex flex-col items-center justify-center text-center font-medium text-secondary-default">
              <Inbox01 size="36" />
              No data
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarSelector;
