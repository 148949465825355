import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomTable from "../../common/table";
import IconButton from "../../common/button";
import Layout from "./layout";
import {
  getSelectedFilters,
  compareValues,
  convertToURLSearchParams,
  getStatusColor,
} from "../my-creators/helper";
import CustomInput from "../../common/input";
import CustomModal from "../../common/modal";
import { Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  createGroup,
  getCreators,
  getFilters,
  getGroups,
  getSingleGroup,
  setCreatorLoading,
  setFilterLoading,
  updateGroup,
} from "../store/creators";
import {
  transformApiData,
  convertToQueryString,
  parseQueryString,
  hasValidData
} from "../my-creators/helper";
import AutomationModal from "./groupAutomationModal";
import CustomFiltersAndOr from "../customFilter/customFiltersAndOr";

const Groups = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [groupName, setGroupName] = useState("");
  const filterLoading = creators.filtersLoading;
  const filters = transformApiData(creators.filters);
  const [conditionBlocks, setConditionBlocks] = useState<any>([
    {
      conditions: [
        { filterKey: undefined, selectedOption: undefined, isNot: false },
      ],
    },
  ]);
  const [validatedConditons, setValidatedConditions] = useState<any>(conditionBlocks);
  const [showAutomationModal, setShowAutomationModal] = useState(false);
  const [loading, setLoading] = useState<any>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const selectedFilters = getSelectedFilters(filters);
  const creatorLoading = creators.creatorLoading;
  const groupsLoading = creators.loading;
  let key = `${shops.selectedStoreId}-${shops.selectedStoreRegion}`;
  if (Object.values(paramsObject).length > 0 && !paramsObject?.id) {
    key = `${shops.selectedStoreId}-${shops.selectedStoreRegion}-${location.search}`;
  }
  if(paramsObject?.id) {
    key = `${shops.selectedStoreId}-${shops.selectedStoreRegion}-?${convertToQueryString(validatedConditons)}`;
  }
  const handleCancel = () => {
    navigate("/groups", { replace: true });
    setLoading(false);
  };

  const getShopCreators = async (dataObject?: any) => {
    await dispatch(
      getCreators({
        shop_id: shops.selectedStoreId,
        shop_region: shops.selectedStoreRegion || "US",
        new_filters: dataObject ? dataObject : parseQueryString((location.search || "").replace("?", "")),
        filtersKey: dataObject ? `?${convertToQueryString(dataObject)}` : location.search,
      })
    );
  };

  const urlParams = convertToURLSearchParams(selectedFilters).toString();

  useEffect(() => {
    if (
      Object.keys(selectedFilters).length > 0 &&
      urlParams &&
      !paramsObject?.id
    ) {
      navigate(`/groups/create?${urlParams}`, {
        replace: true,
      });
    }
  }, [urlParams, location.search]);

  const getCreatorFilters = async () => {
    if (shops?.selectedStoreId) {
      await dispatch(
        getFilters({
          shop_id: shops.selectedStoreId,
        })
      );
    }
  };

  const handleConfirm = () => {
    // Handle confirmation logic here, like redirecting to another step
    setIsModalVisible(false);
    setShowAutomationModal(true);
    // call API here
    // navigate("/automations?type=create");
  };

  const onClickDiscard = () => {
    setIsModalVisible(false);
    setGroupName("");
    navigate("/groups");
  };

  useEffect(() => {
    if (location.search === "") {
      dispatch(setCreatorLoading(false));
    }
    if (Object.keys(creators.filters).length === 0) {
      getCreatorFilters();
    } else {
      dispatch(setFilterLoading(false));
      if (paramsObject?.id) {
        if (creators?.singleGroup?.[paramsObject?.id]) {
          const singleGroup = creators?.singleGroup?.[paramsObject?.id];
          setGroupName(singleGroup.group_name);
          setConditionBlocks(singleGroup.filter_config)
          setValidatedConditions(singleGroup.filter_config)
          const keyType = `${shops.selectedStoreId}-${shops.selectedStoreRegion}-?${convertToQueryString(singleGroup.filter_config)}`;
          if((creators?.creators?.[keyType] || [])?.length === 0) {
            getShopCreators(singleGroup.filter_config);
          }
        } else {
          dispatch(getSingleGroup({ id: paramsObject?.id }));
        }
      } else {
        if (
          (creators?.creators?.[key] || [])?.length === 0 &&
          location.search
        ) {
          if (Object.keys(paramsObject).length) {
            const conditions =  parseQueryString((location.search || "").replace("?", ""))
            setConditionBlocks(conditions)
            getShopCreators();
          }
        }
      }
    }
  }, [
    creators.filters,
    location.search,
    creators?.singleGroup?.[paramsObject?.id]
  ]);

  const creatorsValues = location.search ? creators?.creators?.[key] || [] : [];

  const fallbackImage = "/logo192.png";

  let columns = [
    {
      title: "Creator Name",
      dataIndex: "creator_name",
      key: "creator_name",
      width: 100,
      render: (creatorName: string, record: { avatar_url: string }) => (
        <div className="flex items-center">
          <img
            src={record.avatar_url}
            alt={creatorName}
            className="w-10 h-10 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <Link to={`https://tiktok.com/@${creatorName}`} target="_blank">
              <div>{creatorName}</div>
            </Link>
            <div className="text-gray-500">
              Followers: {record.follower_num}
            </div>
            <Tooltip title={`${record.product_title} ${record.product_id}`}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  width: 200
                }}
                className="text-gray-500"
              >
                Product Name: {record.product_title || "-"}
              </div>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 100,
      render: (tags: string[]) => (
        <div className="flex gap-1 flex-wrap">
          {tags && tags.length > 0 ? (
            tags.map((tag, index) => (
              <Tag
                key={index}
                className="flex bg-gray-200 text-black px-2 py-1 rounded space-between items-center mb-2"
              >
                {tag}
              </Tag>
            ))
          ) : (
            <span className="text-gray-500">No Tags</span>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "curr_status",
      key: "curr_status",
      width: 100,
      render: (status: string) => (
        <span
          className={"border border-stroke px-5 py-2 rounded-md"}
          style={{
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: getStatusColor(status),
            backgroundColor: `${getStatusColor(status)}23`,
            borderColor: `${getStatusColor(status)}80`,
          }}
        >
          {status}
        </span>
      ),
    },
    {
      title: (
        <div className="flex items-center">
          GMV
          <Tooltip title="Gross Merchandise Value">
            <InfoCircleOutlined className="ml-1 text-gray-400" />
          </Tooltip>
        </div>
      ),
      dataIndex: "gmv",
      key: "gmv",
      width: 100,
      render: (gmv: number) => <span>${gmv.toLocaleString()}</span>,
    },
  ];

  const createNewGroup = async () => {
    const result = await dispatch(
      createGroup({
        shop_id: shops.selectedStoreId,
        group_name: groupName,
        new_filters: conditionBlocks,
      })
    );
    if (createGroup.fulfilled.match(result)) {
      if (result.payload.group_id) {
        navigate(`/groups/create?id=${result.payload.group_id}`, {
          replace: true,
        });
      }
      setIsModalVisible(true);
    }
  };

  // Function to get the button label based on conditions
  const getButtonLabel = () => {
    const data = creators?.singleGroup?.[paramsObject?.id];
    if (
      paramsObject.id &&
      !compareValues(
        { ...(data?.filter_config || {}), group_name: data?.group_name },
        { ...conditionBlocks, group_name: groupName }
      )
    ) {
      return "Save Group";
    } else if (paramsObject.id) {
      return "Create Automation";
    } else {
      return "Create Group";
    }
  };

  // Function to handle button click events using switch case
  const handleButtonClick = async () => {
    switch (getButtonLabel()) {
      case "Create Automation":
        setShowAutomationModal(true);
        // navigate("/automations?type=create");
        break;

      case "Create Group":
        createNewGroup();
        break;

      case "Save Group":
        const result = await dispatch(
          updateGroup({
            id: paramsObject.id,
            data: {
              group_id: paramsObject.id,
              shop_id: shops.selectedStoreId,
              new_filters: conditionBlocks,
              group_name: groupName,
            },
          })
        );
        if (updateGroup.fulfilled.match(result)) {
          await dispatch(
            getGroups({
              shop_id: shops.selectedStoreId,
            })
          );
        }
        navigate("/groups", { replace: true });
        // Add your save logic here
        break;

      default:
        console.log("No action defined for this button type");
    }
  };

  const convertToQueryStringAndNavigate = (value) => {
    const query = convertToQueryString(value);  
    if(!(query || '').includes('undefined')) {
      if(paramsObject?.id) {
        setValidatedConditions(value)
        const keyId = `${shops.selectedStoreId}-${shops.selectedStoreRegion}-?${query}`

        if((creators?.creators?.[keyId] || [])?.length === 0) {
          getShopCreators(value)
        }
      } else {
        navigate(`/groups/create?${query}`, {
          replace: true,
        });
      }
    }
    
  };
  const uniqueProductIds = Array.from(
    new Set((creators?.creators?.[key] || [])?.map(el => el.product_id))
  );

  const validData = hasValidData(conditionBlocks)
  console.log("creators?.creators?.[key]", uniqueProductIds)
  return (
    <Layout>
      <AutomationModal
        groupId={paramsObject?.id}
        visible={showAutomationModal}
        setVisible={setShowAutomationModal}
        productIds={uniqueProductIds}
      />
      <CustomModal
        visible={isModalVisible}
        title="Create group"
        onCancel={handleCancel}
        // className="custom-modal"
        width={400}
        content={
          <div>
            <p>
              <strong>{`"${groupName}"`}</strong> created successfully. Do you
              wish to continue to step 2 and set automations?
            </p>
            <div className="flex justify-center gap-2 mt-4">
              <Button
                onClick={handleCancel}
                className="bg-gray-200 w-full"
                style={{ height: 40 }}
              >
                I'll do it later!
              </Button>
              <Button
                className="bg-black text-white w-full"
                style={{ height: 40 }}
                onClick={handleConfirm}
              >
                Set Automation
              </Button>
            </div>
          </div>
        }
      />
      {!loading ? (
        <>
          <div className="flex justify-between items-center mb-10">
            <h3 className="font-semibold text-xl">Create Group</h3>
            <div className="flex">
              <div
                className="flex items-center mr-2 cursor-pointer"
                onClick={() => {
                  onClickDiscard();
                }}
              >
                <h3 className="text-l text-gray-20 mr-2">Discard</h3>
              </div>
              {(groupsLoading || creatorLoading) ? (
                <Spin />
              ) : (
                <IconButton
                  label={getButtonLabel()}
                  onClick={handleButtonClick}
                  buttonClass={
                    "bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-md"
                  }
                  textClass="text-sm font-medium"
                  restProps={{
                    disabled:
                      groupName === "" || !validData
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex h-screen">
            {/* Left side for filters */}
            <div className="w-[45vw] h-6/6 overflow-y-auto">
              <CustomInput
                label="Group name"
                value={groupName}
                onChange={setGroupName}
                placeholder="Enter group name"
              />
              {selectedFilters && (
                <div className="flex flex-row justify-between items-center mb-4">
                  <h4 className="font-bold">Set conditions</h4>
                </div>
              )}
              <div>
                {filterLoading && Object.keys(filters).length > 0 ? (
                  <div className="flex items-center justify-center h-[65vh]">
                    <Spin />
                  </div>
                ) : (
                  <CustomFiltersAndOr
                    filters={filters}
                    conditionBlocks={conditionBlocks}
                    setConditionBlocks={setConditionBlocks}
                    convertToQueryString={convertToQueryStringAndNavigate}
                    labelStyles={{ fontSize: "14px" }}
                    selectedClassName="text-white"
                    optonClassName="text-grayCustom text-custom-16"
                  />
                )}
              </div>
            </div>

            {/* Right side for the table, sticky */}
            <div className="flex-2 items-center justify-center ml-5 w-full sticky top-0 h-screen">
              <div className="flex items-center justify-center">
                <span className="text-grayCustom">Total creators preview:</span>
                <span>
                  &nbsp;
                  {[
                    ...new Set(
                      creatorsValues.map((item: any) => item.creator_name)
                    ),
                  ].length || 0}
                </span>
              </div>
              {creatorLoading ? (
                <div className="flex items-center justify-center h-1/2">
                  <Spin />
                </div>
              ) : (
                <CustomTable
                  columns={columns}
                  data={creatorsValues.slice(0, 50)}
                  loading={false}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center mr-3">
          <Spin
            size="small"
            style={{
              color: "#3B82F6",
              fontSize: "20px",
            }}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
          />
        </div>
      )}
    </Layout>
  );
};

export default Groups;
