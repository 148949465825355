import React from "react";
import PlanGuard from "../components/PlanGuard";
import DashboardHeading from "./components/DashboardHeading";
import ReusableTable from "./components/TableComponent";
import { Download01 } from "untitledui-js-base";
import { Tag } from "antd";
import { data } from "../dashboard-new/components/messageTableData";
import MessagedCreators from "../messagedcreators";

const MessagedCreator = () => {
  const CustomTableTitle = () => {
    return (
      <div className="flex flex-row px-3xl py-2xl">
        <div className="h-14 flex flex-row space-x-md items-center py- flex-1">
          <p className="text-lg font-semibold text-primary-default">
            Messaged Creators
          </p>
          <div className="flex items-center py-xxs px-md rounded-full border text-xs font-medium border-utility-brand-200 text-utility-brand-700 bg-utility-brand-50">
            25k
          </div>
        </div>
        <div>
          <button className="flex flex-row rounded-md border border-secondary px-3.5 py-2.5 shadow-xs text-secondary-default space-x-xs items-center justify-center gap-xs">
            <Download01 size="20" className="font-semibold" />
            <p className="font-semibold text-sm">Download message history</p>
          </button>
        </div>
      </div>
    );
  };
  const columns = [
    {
      name: "Creator Name",
      render: (row) => (
        <div className="flex flex-row items-center space-x-lg">
          <img
            height={40}
            width={40}
            src={row.creator.pic}
            alt={row.creator.name}
          />
          <p className="font-medium text-primary-default">{row.creator.name}</p>
        </div>
      ),
    },
    {
      name: "Automation Name",
      render: (row) => (
        <p className="text-tertiary-default">{row.automationName}</p>
      ),
    },
    {
      name: "Replied",
      render: (row) => (
        <Tag
          className={`${
            row.repliedStatus === "Replied"
              ? "text-utility-success-700 border-utility-success-200 bg-utility-success-50"
              : "text-utility-error-700 border-utility-error-200 bg-utility-error-50"
          } font-medium pb-xxs px-md border rounded-sm`}
        >
          {row.repliedStatus}
        </Tag>
      ),
    },
    {
      name: "Date",
      render: (row) => <p className="text-tertiary-default">{row.date}</p>,
    },
    {
      name: "Actions",
      render: (row, setCurrMessage, showModal) => (
        <p
          className="font-semibold text-utility-brand-700 text-center cursor-pointer"
          onClick={() => {
            setCurrMessage(row.message);
            showModal();
          }}
        >
          View Message
        </p>
      ),
    },
  ];

  return (
    <PlanGuard>
      <div className="flex flex-col">
        <DashboardHeading />
        <MessagedCreators />
        {/* <ReusableTable
          cols={columns}
          rows={data}
          pageSize={10}
          TableTitleComponent={CustomTableTitle}
        /> */}
        
      </div>
    </PlanGuard>
  );
};

export default MessagedCreator;
