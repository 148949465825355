import React, { useState } from "react";
import { Table, Button, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

interface DataType {
  key: string;
  [key: string]: any;
}

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
  width?: number;
  render?: (text: any, record: DataType) => React.ReactNode;
}

interface CustomTableProps {
  data: DataType[];
  loading?: boolean;
  columns: ColumnType[];
  labels?: any; // Optional label mapping for export headers
  showDownloadOptions?: boolean; // Control visibility of download options
  tableClassName?: string;
  rowSelection?: any;
  restProps?: any;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  loading = false,
  columns,
  labels,
  showDownloadOptions = false,
  tableClassName = "",
  rowSelection,
  restProps = {},
}) => {
  const [pageSize, setPageSize] = useState<number>(10);

  // Handler for page size change
  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  // Flattening function for nested data
  const flattenData = (data) => {
    return data.map((row) => {
      const flattenedRow = {};
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === "object" && row[key] !== null) {
          // Convert object to JSON string for export
          flattenedRow[key] = JSON.stringify(row[key]);
        } else {
          flattenedRow[key] = row[key];
        }
      });
      return flattenedRow;
    });
  };

  // Download handler function
  const handleDownload = (format) => {
    // Map data based on labels if provided, and flatten nested objects
    const exportData = flattenData(data).map((row) => {
      const rowData = {};
      if (labels) {
        // Use labels to control which fields are included and how they are named
        Object.keys(labels).forEach((key) => {
          rowData[labels[key]] = row[key];
        });
      } else {
        // Include all fields without changing names
        Object.keys(row).forEach((key) => {
          rowData[key] = row[key];
        });
      }
      return rowData;
    });

    if (format === "xlsx") {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      downloadBlob(blob, "ReacherData.xlsx");
    } else if (format === "csv") {
      const csvContent = XLSX.utils.sheet_to_csv(
        XLSX.utils.json_to_sheet(exportData)
      );
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      downloadBlob(blob, "ReacherData.csv");
    } else if (format === "xml") {
      let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<rows>\n';
      exportData.forEach((row) => {
        xmlContent += "  <row>\n";
        Object.keys(row).forEach((key) => {
          xmlContent += `    <${key}>${row[key] || ""}</${key}>\n`;
        });
        xmlContent += "  </row>\n";
      });
      xmlContent += "</rows>";
      const blob = new Blob([xmlContent], {
        type: "application/xml;charset=utf-8;",
      });
      downloadBlob(blob, "ReacherData.xml");
    }
  };

  // Helper functions
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Space>
          {showDownloadOptions && (
            <>
              <Button
                onClick={() => handleDownload("xlsx")}
                icon={<DownloadOutlined />}
                style={{
                  backgroundColor: "#4CAF50",
                  borderColor: "#4CAF50",
                  color: "#fff",
                }}
              >
                XLSX
              </Button>
              <Button
                onClick={() => handleDownload("csv")}
                icon={<DownloadOutlined />}
                style={{
                  backgroundColor: "#2196F3",
                  borderColor: "#2196F3",
                  color: "#fff",
                }}
              >
                CSV
              </Button>
            </>
          )}
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          pageSize,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
        }}
        className={tableClassName}
        rowSelection={rowSelection}
        {...restProps}
      />
    </div>
  );
};

export default CustomTable;
