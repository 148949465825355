import React, { useEffect } from "react";
import PlanGuard from "../../components/PlanGuard";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getShopLogs } from "../../store/shops";

const AdminLogs = () => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getShopLogs({}));
  }, [auth]);

  const failedContainerShops = shops.shopLogs.failed_container_shops || [];
  const failedLoginShops = shops.shopLogs.failed_login_shops || [];

  return (
    <PlanGuard>
      <div className="flex gap-4">
        <div className="relative overflow-x-auto w-[30%] border border-secondary rounded-xl p-2">
          <h3 className="font-semibold text-lg mb-4">Failed Containers</h3>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Machine
                </th>
              </tr>
            </thead>
            <tbody>
              {failedContainerShops.map((item) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {item.account_email}
                  </th>
                  <td className="px-6 py-4">{item.machine}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="relative overflow-x-auto w-[70%] border border-secondary rounded-xl p-2">
          <h3 className="font-semibold text-lg mb-4">Failed Login Attempts</h3>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Machine
                </th>
                <th scope="col" className="px-6 py-3">
                  Failed login count
                </th>
                <th scope="col" className="px-6 py-3">
                  Container running
                </th>
              </tr>
            </thead>
            <tbody>
              {failedLoginShops.map((item) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {item.account_email}
                  </th>
                  <td className="px-6 py-4">{item.machine}</td>
                  <td className="px-6 py-4">{item.failed_login_attempts}</td>
                  <td className="px-6 py-4">
                    {item.is_container_running ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </PlanGuard>
  );
};

export default AdminLogs;
