import React, { useEffect, useState } from "react";
import PlanGuard from "../components/PlanGuard";
import DashboardHeading from "./components/DashboardHeading";
import { HelpCircle, SearchLG, XClose } from "untitledui-js-base";
import dayjs from "dayjs";
import CustomDateRangePicker from "./components/Calendar";
import LineGraph from "./components/LineGraph";
import WeeklyOutreach from "./components/WeeklyOutreach";
import { getShopAnalytics, getShopStatistics } from "../store/shops";
import { useAppDispatch, useAppSelector } from "../hooks";
import SidebarSelector from "../components/SidebarSelector";
import { getAutomations } from "../store/automation";
import { getCurrencySign } from "../my-creators/helper";
import { Tooltip } from "antd";
import AdminLogs from "./components/adminLogs";

const categoryLabels = {
  all: "Total Unique Creators",
  messages: "DM Creators",
  target_collabs: "Target Collaboration",
};

const keyMapping = {
  gmv_value: "Total GMV",
  total_creator_outreach_limit: "Num. Unconnected Creators",
  creator_follower_count: "Creator Follower Count",
};

const defaultKeyMapping = {
  gmv_value: null,
  total_creator_outreach_limit: "Unlimited",
  creator_follower_count: "Unlimited",
};

const Analytics = () => {
  const [selected, setSelected] = useState("all");
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const admin = useAppSelector((state) => state.admin);
  const [dates, setDates] = useState({
    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const shops = useAppSelector((state) => state.shops);
  const automation = useAppSelector((state) => state.automation);
  const dispatch = useAppDispatch();

  const selectedTypeAnalytics =
    (((shops?.shopAnalytics || {})?.[shops.selectedStoreId] || {})[selected] ||
      {})?.[
      `${dates.startDate}-${dates.endDate}${selectedAutomation ? `-${selectedAutomation}` : ""}`
    ] || [];

  console.log("selectedAutomation12312", shops?.shopAnalytics);
  console.log("selectedAutomation12312selectedAutomation", selectedAutomation);

  const singleCustomerAutomations =
    automation.data?.[shops.selectedStoreId] || [];

  useEffect(() => {
    if (shops.selectedStoreId) {
      dispatch(getShopStatistics({ shop_id: shops.selectedStoreId }));
    }
    if ((selectedTypeAnalytics || []).length === 0 && shops.selectedStoreId) {
      dispatch(
        getShopAnalytics({
          shop_id: shops.selectedStoreId,
          data_type: selected,
          start_date: dates.startDate,
          end_date: dates.endDate,
          automation_id: selectedAutomation,
        })
      );
      if (singleCustomerAutomations.length === 0) {
        dispatch(
          getAutomations({
            shop_id: shops.selectedStoreId,
          })
        );
      }
    }
  }, [
    selected,
    dates.startDate,
    dates.endDate,
    shops.selectedStoreId,
    selectedAutomation,
  ]);

  const filterDateRange = (
    startDate: Date | null,
    endDate: Date | null
  ): void => {
    if (!startDate || !endDate) {
      return;
    }
    setDates({
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    });
  };

  const validateAmount = (amount: any) => {
    // Check if the amount is a valid number
    if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
      return getCurrencySign(shops.selectedStoreRegion);
    } else {
      // If it's not a valid number, append a pound sign
      return "";
    }
  };

  return (
    <PlanGuard>
      <div className="flex flex-col">
        <DashboardHeading />
        <div className="bg-secondary-default p-6 mt-8 mb-6 rounded-3xl flex flex-col">
          <span className="flex flex-row items-center gap-x-2 mb-4">
            <p className="font-semibold text-primary-default text-lg">
              Weekly Outreach Limit
            </p>
            <Tooltip title="This is the weekly outreach limit that TikTok has set based on your shop's 30-day GMV. You can find more information in your Affiliate Center - but we've surfaced the most important information here for your convenience!">
              <HelpCircle className="text-quaternary-default" size="16" />
            </Tooltip>
          </span>
          <div className="border border-secondary rounded-xl flex flex-row justify-center overflow-hidden mb-6">
            {Object.entries(keyMapping).map(([key, value], index) => (
              <WeeklyOutreach
                key={index}
                name={value}
                prepend={
                  key === "gmv_value"
                    ? validateAmount(
                        ((shops?.shopStatistics || {})?.[
                          shops.selectedStoreId
                        ] || {})?.[key]
                      )
                    : ""
                }
                defaultValue={defaultKeyMapping[key]}
                value={
                  ((shops?.shopStatistics || {})?.[shops.selectedStoreId] ||
                    {})?.[key]
                }
              />
              // typeof value === "number" ? value.toLocaleString() : value
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mb-4">
          <p className="font-semibold text-primary-default text-lg">
            Creators Messaged
          </p>
          <div className="flex flex-row space-x-3 items-center">
            {selectedAutomation ? (
              <button
                className="flex items-center space-x-1 focus:outline-none border border-primary focus:border-brand-default h-11 rounded-md px-5"
                onClick={() => setSelectedAutomation(null)}
              >
                <XClose size="18" className="text-quaternary-default" />{" "}
                <p>Clear Automation</p>
              </button>
            ) : (
              <></>
            )}

            <SidebarSelector
              options={(singleCustomerAutomations || []).map((automation) => ({
                label: automation.automation_name,
                value: automation.automation_id,
              }))}
              value={selectedAutomation}
              onChange={setSelectedAutomation}
              placeHolder="Search by automation name"
              preDefinedValue={false}
              LeftIcon={
                <SearchLG className="text-quaternary-default me-1" size="18" />
              }
              containerstyle={{ width: "363px" }}
              inputClassName={"h-11 rounded-md"}
            />
            <CustomDateRangePicker filterDateRange={filterDateRange} defaultStartDate={new Date(dates.startDate)} defaultEndDate={new Date(dates.endDate)} />
          </div>
        </div>

        <div className="rounded-3xl border border-secondary pt-4 px-2 mb-10">
          <div className="flex flex-row text-quaternary-default font-semibold my-2 mx-3 text-sm rounded-md cursor-pointer overflow-hidden text-center bg-secondary-default border border-secondary">
            {Object.keys(categoryLabels).map((category) => (
              <div
                key={category}
                onClick={() => {
                  setSelected(category);
                }}
                className={`flex-1 grid place-items-center h-9 text-quaternary-default hover:text-tertiary-default px-3 ${
                  selected === category &&
                  "bg-primary-default text-primary-default shadow-md rounded-md border border-primary"
                }`}
              >
                <span className="grid place-items-center ease-in-out duration-300 h-full w-full">
                  {categoryLabels[category]}{" "}
                  {/* Use category label from JSON */}
                </span>
              </div>
            ))}
          </div>
          <LineGraph data={selectedTypeAnalytics} />
          
        </div>

        {
          admin.isAdmin && <AdminLogs />
        }
        
      </div>
    </PlanGuard>
  );
};

export default Analytics;
