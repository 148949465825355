import { useLocation } from "react-router-dom";
import SidebarNew from "./SidebarNew";

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Wrapper = (props: P) => {
    const pathname = useLocation().pathname;

    return ["/login", "/signup"].includes(pathname) ? (
      <WrappedComponent {...props} />
    ) : (
      <div className="flex h-screen overflow-hidden bg-primary-default">
        <SidebarNew />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-primary-default">
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <WrappedComponent {...props} />
            </div>
          </main>
        </div>
      </div>
    );
  };

  return Wrapper;
};

export default withAuth;
